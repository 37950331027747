<template>
  <div class="manual">
    <div v-if="manualCategoryList.length == 0">
      <span v-permission="'Manual.Manual'" class="tool-btn add-category" @click="operateCategory('add')"
        ><svg-icon icon-class="addManual"></svg-icon
      ></span>
      <nodata v-if="manualCategoryList.length === 0" />
    </div>
    <div class="categoryGroup" v-for="(category, cIndex) in manualCategoryList" :key="cIndex" v-loading="loading">
      <div class="categoryTitle">
        <div class="panel-title">
          {{ category.manualTypeName }}
        </div>
        <div class="buttonGroup" v-permission="'Manual.Manual'">
          <span v-if="!category.isCanDelete" class="tool-btn add-category" @click="operateCategory('add')"
            ><svg-icon icon-class="addManual"></svg-icon
          ></span>
          <span class="tool-btn edit-category" @click="operateCategory('edit', category)"
            ><svg-icon icon-class="editManual"></svg-icon
          ></span>
          <span
            v-if="category.isCanDelete"
            class="tool-btn del-category"
            @click="clickDeleteManualCategory(category.id)"
            ><svg-icon icon-class="delete"></svg-icon
          ></span>
          <span class="category-tool-order" style="line-height:20px">{{
            $t("labelcategoryOrder") + category.manualTypeOrder
          }}</span>
        </div>
        <el-link
          v-permission="'Manual.Manual'"
          class="add-manual"
          style="line-height:30px"
          @click="operateManual('add', category.id, cIndex)"
          icon="el-icon-circle-plus-outline"
          >{{ $t("addManualBtn") }}</el-link
        >
      </div>
      <div class="categoryList">
        <div
          class="categoryItem                                                                                                                         "
          v-for="(item, mIndex) in category.manualRecords"
          :key="mIndex"
        >
          <div class="content">
            <div class="title" :title="item.manualName">{{ item.manualName }}</div>
            <div class="subTitle">
              <a :title="item.fileName" @click="manualDonwload(item.id)" style="cursor: pointer;">{{
                item.fileName
              }}</a>
            </div>
          </div>
          <div class="tool">
            <span
              v-permission="'Manual.Manual'"
              class="tool-btn edit-manual"
              @click="operateManual('edit', category.id, cIndex, item)"
              ><svg-icon icon-class="editManual"></svg-icon
            ></span>
            <span
              v-permission="'Manual.Manual'"
              class="tool-btn del-manual"
              @click="clickDeleteManual(item.id, category.id, cIndex)"
              ><svg-icon icon-class="delete"></svg-icon
            ></span>
            <span class="tool-btn open-link" @click="manualDonwload(item.id)"
              ><svg-icon icon-class="download"></svg-icon
            ></span>
          </div>
        </div>
        <nodata v-if="category.manualRecords.length == 0" />
      </div>
    </div>
    <modal-dialog
      :show-dialog.sync="isShowManualDialog"
      :dialog-title="$t('manualDialogTitle')"
      :show-footer="true"
      dialogWidth="700px"
      @commitDialog="clickSubmitManual('manualForm')"
    >
      <el-form ref="manualForm" :model="manualForm" :rules="manualRules" label-width="150px" :show-message="true">
        <el-row :gutter="10">
          <el-col :span="16">
            <el-form-item :label="$t('labelManualName')" prop="name">
              <el-input v-model.trim="manualForm.name" maxlength="100" minlength="1" show-word-limit></el-input>
              <div class="filename-box" :title="manualFile.name">
                <i class="el-icon-paperclip"></i>{{ $t("labelFileName") + manualFile.name }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label-width="0" prop="file">
              <el-upload
                class="upload-demo"
                action=""
                ref="upload"
                :on-change="fileChange"
                :file-list="manualForm.fileList"
                :auto-upload="false"
                :show-file-list="false"
                :limit="1"
                :multiple="false"
              >
                <el-button class="upload" custom-class="upload-btn" type="primary">{{ $t("uploadFileBtn") }}</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </modal-dialog>
    <modal-dialog
      :show-dialog.sync="isShowCategoryDialog"
      :dialog-title="$t('categoryDialogTitle')"
      :show-footer="true"
      @commitDialog="clickSubmitManualCategory('manualCategoryForm')"
      dialogWidth="700px"
    >
      <el-form
        ref="manualCategoryForm"
        :model="manualCategoryForm"
        :rules="manualCategoryRules"
        label-width="170px"
        label-position="top"
      >
        <el-form-item :label="$t('labelCategoryName')" prop="name">
          <el-input v-model.trim="manualCategoryForm.name" maxlength="50" minlength="1" show-word-limit></el-input>
        </el-form-item>
        <div class="manual-category-order">
          <el-form-item :label="$t('labelcategoryOrder')" prop="order">
            <el-input v-model.number="manualCategoryForm.order" min="1" max="99999" step="1"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </modal-dialog>
  </div>
</template>

<script>
import i18n from "@/lang";
import { isSuperAdmin } from "utils/index";
import {
  getManualCategoryList,
  getSingleCategory,
  submitAddManualCategory,
  submitEditManualCategory,
  deleteManualCategory,
  submitAddManual,
  submitEditManual,
  deleteManual,
  uploadManualFile,
  downloadManualFile
} from "api/help";
export default {
  data() {
    const validateFile = (rule, file, callback) => {
      //文件非空校验
      if (file.fileName === "" || file.fileSize < 1) {
        return callback(new Error(this.$t("validFileNameRequired")));
      }
      // //文件格式校验
      // let fileExtension = "";
      // if (file.fileName.lastIndexOf(".") > -1) {
      //   fileExtension = file.fileName.slice(file.fileName.lastIndexOf("."));
      // } else {
      //   return callback(new Error(this.$t("validFileType")));
      // }
      // if (!this.fileType.includes(fileExtension)) {
      //   return callback(new Error(this.$t("validFileType")));
      // }
      //文件大小校验
      let isLimited = file.fileSize / 1024 / 1024 < this.fileSize;
      if (!isLimited) {
        return callback(new Error(this.$t("validFileSizeRequired")));
      }
      callback();
    };
    return {
      loading: false,
      // // 可上传的文件类型
      // accept: ".pdf,.xlsx,.xls,.doc,.docx,.png,.jpg,.jpeg,.gif,.ppt,.pptx",
      // fileType: [".pdf", ".xlsx", ".xls", ".doc", ".docx", ".png", ".jpg", ".jpeg", ".gif", ".ppt", ".pptx"],
      // 大小限制(MB)
      fileSize: 10,
      manualCategoryList: [],
      isShowManualDialog: false,
      isShowCategoryDialog: false,
      isFileChange: false,
      isCanSubmitManual: true,
      isCanSubmitCategory: true,
      isCanDeleteManual: true,
      isCanDeleteCategory: true,
      isLoading: true,
      manualForm: {
        name: "",
        file: {
          fileName: "",
          fileSize: 0
        },
        fileList: []
      },
      manualRules: {
        name: [
          { required: true, message: this.$t("validManualNameRequired"), trigger: ["blur", "change"] },
          { min: 1, max: 100, message: this.$t("validManualNameLength"), trigger: ["blur", "change"] }
        ],
        file: [{ validator: validateFile, trigger: "change" }]
      },
      manualCategoryForm: {
        id: "",
        name: "",
        order: 1
      },
      manualCategoryRules: {
        name: [
          { required: true, message: this.$t("validCategoryNameRequired"), trigger: ["blur", "change"] },
          { min: 1, max: 50, message: this.$t("validCategoryNameLength"), trigger: ["blur", "change"] }
        ],
        order: [
          { required: true, message: this.$t("validCategoryOrderRequired"), trigger: ["blur", "change"] },
          {
            type: "number",
            min: 1,
            max: 99999,
            message: this.$t("validCategoryOrderLength"),
            trigger: ["blur", "change"]
          }
        ]
      },
      manualFile: {
        name: "",
        url: ""
      },
      uploadFileList: [],
      operateManualType: "",
      operateManualCategoryType: "",
      manualTypeId: null,
      categoryIndex: null,
      manualId: null,
      isSuperAdmin: isSuperAdmin()
    };
  },
  mounted() {
    this.isLoading = false;
    this.openFullScreen();
    getManualCategoryList().then(response => {
      this.manualCategoryList = response.result;
      this.isLoading = true;
    });
  },
  methods: {
    manualDonwload(fileId) {
      this.loading = true;
      downloadManualFile(fileId)
        .then(res => {
          this.loading = false;
          if (res.status === 200) {
            const encodedFilename = res.headers["file-name"];
            // 解码文件名
            const filename = decodeURIComponent(encodedFilename);

            // 假设res.data是文件内容
            const content = res.data;

            // 创建Blob对象并生成下载链接
            const blob = new Blob([content], { type: "application/octet-stream" });
            const downloadUrl = URL.createObjectURL(blob);

            // 创建临时的<a>元素并触发下载
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", filename); // 设置下载文件名
            document.body.appendChild(link);
            link.click();

            // 清理
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch(err => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: err,
            type: "error",
            duration: 5000
          });
        });
    },
    downloadPDF(fileUrl) {
      window.open(encodeURI(fileUrl));
    },
    //操作手册记录表单初始化
    manualFormInit() {
      this.manualForm = {
        name: "",
        file: {
          fileName: "",
          fileSize: 0
        },
        fileList: []
      };
      this.manualFile = {
        name: "",
        url: ""
      };
      this.isFileChange = false;
      this.manualTypeId = null;
      this.categoryIndex = null;
    },
    //操作手册分类表单初始化
    manualCategoryFormInit() {
      this.manualCategoryForm = {
        name: "",
        order: 1
      };
    },
    //刷新操作手册记录列表
    refreshManualList(categoryId, categoryIndex) {
      getSingleCategory({ manualtypeId: categoryId }).then(response => {
        this.manualCategoryList.splice(categoryIndex, 1, response.result);
      });
    },
    //刷新操作手册分类列表
    refreshManualCategoryList() {
      getManualCategoryList().then(response => {
        this.manualCategoryList = response.result;
      });
    },
    //打开 添加&编辑 操作手册记录对话框
    operateManual(type, categoryId, categoryIndex, item) {
      this.manualFormInit();
      this.isShowManualDialog = true;
      this.operateManualType = type;
      this.manualTypeId = categoryId;
      this.categoryIndex = categoryIndex;
      if (type == "edit" && item) {
        this.manualForm.name = item.manualName;
        this.manualForm.file.fileName = item.fileName;
        this.manualForm.file.fileSize = 1;
        this.manualId = item.id;
        this.manualFile = {
          name: item.fileName,
          url: item.url
        };
      }
    },
    //提交 添加&编辑 操作手册记录
    clickSubmitManual(formName) {
      let lastIndex = this.$refs[formName].length - 1;
      this.$refs[formName].validate(valid => {
        if (valid && this.isCanSubmitManual) {
          this.isCanSubmitManual = false;
          if (this.isFileChange) {
            this.isLoading = false;
            this.openFullScreen();
            let formData = new FormData();
            formData.append("file", this.manualFile.raw);
            uploadManualFile(formData).then(response => {
              if (response.result) {
                let params = {
                  manualName: this.manualForm.name,
                  fileName: this.manualFile.name,
                  url: response.result
                };
                if (this.operateManualType == "add") {
                  params.manualTypeId = this.manualTypeId;
                  submitAddManual(params).then(response => {
                    if (response.result) {
                      this.refreshManualList(this.manualTypeId, this.categoryIndex);
                    }
                    this.isShowManualDialog = false;
                    this.isCanSubmitManual = true;
                    this.isLoading = true;
                  });
                } else if (this.operateManualType == "edit") {
                  params.id = this.manualId;
                  submitEditManual(params).then(response => {
                    if (response.result) {
                      this.refreshManualList(this.manualTypeId, this.categoryIndex);
                    }
                    this.isShowManualDialog = false;
                    this.isCanSubmitManual = true;
                    this.isLoading = true;
                  });
                }
              } else {
                this.isShowManualDialog = false;
                this.isCanSubmitManual = true;
                this.isLoading = true;
              }
            });
          } else if (this.operateManualType == "edit") {
            let editParams = {
              id: this.manualId,
              manualName: this.manualForm.name,
              fileName: this.manualFile.name,
              url: this.manualFile.url
            };
            submitEditManual(editParams).then(response => {
              if (response.result) {
                this.refreshManualList(this.manualTypeId, this.categoryIndex);
              }
              this.isShowManualDialog = false;
              this.isCanSubmitManual = true;
            });
          }
        } else {
          return false;
        }
      });
    },
    //删除 操作手册记录
    clickDeleteManual(manualId, categoryId, categoryIndex) {
      this.$confirm(this.$t("confirmDelManual"), this.$t("confirmDailogTitle"), {
        confirmButtonText: i18n.t("confirm"),
        cancelButtonText: i18n.t("cancel"),
        type: "warning"
      }).then(() => {
        if (this.isCanDeleteManual) {
          this.isCanDeleteManual = false;
          deleteManual({ id: manualId }).then(response => {
            if (response.result) {
              this.refreshManualList(categoryId, categoryIndex);
            }
            this.isCanDeleteManual = true;
          });
        }
      });
    },
    //打开 添加&编辑 操作手册分类对话框
    operateCategory(type, category) {
      this.manualCategoryFormInit();
      this.isShowCategoryDialog = true;
      this.operateManualCategoryType = type;
      if (type == "edit" && category) {
        this.manualCategoryForm.id = category.id;
        this.manualCategoryForm.name = category.manualTypeName;
        this.manualCategoryForm.order = category.manualTypeOrder;
      }
    },
    //提交 添加&编辑 操作手册分类
    clickSubmitManualCategory(formName) {
      let lastIndex = this.$refs[formName].length - 1;
      this.$refs[formName].validate(valid => {
        if (valid && this.isCanSubmitCategory) {
          let params = {
            manualTypeName: this.manualCategoryForm.name,
            manualTypeOrder: this.manualCategoryForm.order
          };
          this.isCanSubmitCategory = false;
          if (this.operateManualCategoryType == "add") {
            submitAddManualCategory(params).then(response => {
              if (response.result) {
                this.refreshManualCategoryList();
              }
              this.isShowCategoryDialog = false;
              this.isCanSubmitCategory = true;
            });
          } else if (this.operateManualCategoryType == "edit") {
            params.id = this.manualCategoryForm.id;
            submitEditManualCategory(params).then(response => {
              if (response.result) {
                this.refreshManualCategoryList();
              }
              this.isShowCategoryDialog = false;
              this.isCanSubmitCategory = true;
            });
          }
        } else {
          return false;
        }
      });
    },
    //删除 操作手册分类
    clickDeleteManualCategory(categoryId) {
      this.$confirm(this.$t("confirmDelManualCategory"), this.$t("confirmDailogTitle"), {
        confirmButtonText: i18n.t("confirm"),
        cancelButtonText: i18n.t("cancel"),
        type: "warning"
      }).then(() => {
        if (this.isCanDeleteCategory) {
          this.isCanDeleteCategory = false;
          deleteManualCategory({ id: categoryId }).then(response => {
            if (response.result) {
              this.refreshManualCategoryList();
            }
            this.isCanDeleteCategory = true;
          });
        }
      });
    },

    //上传文件列表change
    fileChange(file) {
      let lastIndex = this.$refs["manualForm"].length - 1;
      this.manualForm.file.fileName = file.name;
      this.manualForm.file.fileSize = file.size;
      this.manualFile = file;
      this.uploadFileList = [file];
      this.manualForm.fileList = [];
      this.isFileChange = true;
      this.$refs["manualForm"].validate("file");
    },
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.4)"
      });
      let interval = setInterval(() => {
        if (this.isLoading) {
          clearInterval(interval);
          loading.close();
        }
      }, 200);
    }
  }
};
</script>
<style lang="scss" scoped>
.setting-nav-title {
  height: 40px;
  width: 100%;
  clear: right;
}
.setting-nav-title h3 {
  float: left;
}

.category-tool-box {
  float: left;
  margin-left: 20px;
}

.content {
  width: 100%;
  padding-right: 200px;
}

.con-title {
  height: 28px;
  // padding-right: 200px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tool-box {
  position: absolute;
  right: 0;
  top: 50%;
  height: 20px;
  max-width: 100px;
  transform: translate(0, -50%);
}
.tool-btn {
  margin-left: 10px;
}

.upload-demo {
  width: 100%;
}

.add-manual {
  float: right;
  margin-right: 50px;
  font-size: 16px;
}

.add-manual i {
  font-size: 18px;
}

.category-box {
  margin-top: 20px;
}

.categoru-title {
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filename-box {
  width: 100%;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noDataTips {
  font-size: 24px;
  color: rgb(173, 173, 173);
  text-align: center;
  margin: 20px 0 20px;
}

.manual-category-order {
  width: 300px;
}

.category-tool-order {
  font-size: 16px;
  font-weight: normal;
  padding-left: 10px;
}

.manual-list .title,
.manual-list .con-title {
  margin-right: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.manual-list .con-title a {
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  color: #000;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.manual-list .con-title a:hover {
  color: #409eff !important;
  text-decoration: underline;
}

.manual-list .con-title a:visited {
  color: #000;
  text-decoration: none;
}
</style>
