import http from "../http";

//查询全部手册分类列表
export const getManualCategoryList = params => {
  return http({
    method: "get",
    url: `/api/manual/type/list`
  });
};

//查询单个手册分类
export const getSingleCategory = params => {
  return http({
    method: "get",
    url: `/api/manual/type`,
    params
  });
};

//创建手册分类
export const submitAddManualCategory = data => {
  return http({
    method: "post",
    url: `/api/manual/type/create`,
    data
  });
};

//更新手册分类
export const submitEditManualCategory = data => {
  return http({
    method: "put",
    url: `/api/manual/type/update`,
    data
  });
};

//删除手册分类
export const deleteManualCategory = data => {
  return http({
    method: "delete",
    url: `/api/manual/type/delete`,
    data
  });
};

//创建手册记录
export const submitAddManual = data => {
  return http({
    method: "post",
    url: `/api/manual/record/create`,
    data
  });
};

//更新手册记录
export const submitEditManual = data => {
  return http({
    method: "put",
    url: `/api/manual/record/update`,
    data
  });
};

//删除手册记录
export const deleteManual = data => {
  return http({
    method: "delete",
    url: `/api/manual/record/delete`,
    data
  });
};

//上传手册附件
export const uploadManualFile = data => {
  return http({
    method: "post",
    url: `/api/manual/record/uploadfile`,
    data,
    headers: {
      "Content-Type": "multipart/form-data;"
    }
  });
};

export const downloadManualFile = fileId => {
  return http({
    method: "get",
    url: `/api/manual/downloadfile?FileId=${fileId}`,
    responseType: "blob"
  });
};
